<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Bulk printer queue</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Bulk printer
                  queue
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar"></div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12">
                  <div class="breadcrumb pt-3 pb-0 d-flex justify-space-between">
                    <ul>
                      <li class="list-style-none">
                        <strong> Print job title</strong>: {{ userNotification.title }}
                      </li>
                      <li class="list-style-none">
                        <strong> Created by</strong>:
                        {{ userNotification.admin_full_name }}
                      </li>
                      <li class="list-style-none">
                        <strong> Created at</strong>:
                        {{ userNotification.formatted_date_sent }}
                      </li>
                    </ul>
                  </div>
                </v-col>

                <v-col cols="12" md="2">
                  <v-btn
                    color="red"
                    class="text-white ml-3"
                    medium
                    @click="deleteNotification"
                    :loading="isDeleting"
                  >
                    Remove this print job
                  </v-btn>
                </v-col>

                <v-col cols="12" md="10" class="text-right">
                  <v-btn
                    color="red"
                    class="text-white ml-3"
                    medium
                    :loading="isPrintJobDeleting"
                    v-if="printJobs.length > 0"
                    @click="deletePrintJobs"
                    :disabled="print_job_ids.length == 0"
                  >
                    Remove from list
                  </v-btn>

                  <v-btn
                    color="orange"
                    class="text-white ml-3"
                    medium
                    :loading="printLabelLoading"
                    v-if="printJobs.length > 0 && printJobs[0].type == 'certificate'"
                    @click="printLabels"
                    :disabled="print_job_ids.length == 0"
                  >
                    Print label
                  </v-btn>

                  <v-btn
                    color="blue"
                    class="text-white ml-3"
                    medium
                    v-if="printJobs.length > 0 && printJobs[0].type == 'certificate'"
                    :loading="isMarkAsDispatchedLoading"
                    @click="markAsDispatched"
                    :disabled="print_job_ids.length == 0"
                  >
                    Mark as sent
                  </v-btn>

                  <v-btn
                    color="success"
                    class="text-white ml-3"
                    medium
                    :loading="isMarkAsCompleteLoading"
                    @click="markAsComplete"
                    :disabled="print_job_ids.length == 0"
                  >
                    Mark as printed
                  </v-btn>

                  <v-btn
                    color="blue"
                    class="text-white ml-3"
                    medium
                    @click="exportBulkPrinter"
                  >
                    Export csv
                  </v-btn>
                </v-col>

                <v-col cols="12" md="12" class="text-right">
                  <div class="table-responsive">
                    <v-skeleton-loader type="table-thead" v-if="loading">
                    </v-skeleton-loader>

                    <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                    </v-skeleton-loader>

                    <table class="table" v-if="!loading">
                      <thead>
                        <tr class="text-left">
                          <th class="px-3">
                            <v-checkbox
                              v-if="showCheckAllBox"
                              @click="checkAllPrintJob"
                              v-model="checkAllPrintJobFlag"
                            ></v-checkbox>
                          </th>
                          <th
                            class="px-3"
                            v-if="!isLabel"
                            style="max-width: 150px; white-space: pre-wrap"
                          >
                            Candidate details
                          </th>
                          <th
                            class="px-3"
                            v-if="!isLabel"
                            style="max-width: 150px !important; white-space: pre-wrap"
                          >
                            Enroller
                          </th>
                          <th
                            class="px-3"
                            v-if="!isLabel"
                            style="max-width: 120px !important; white-space: pre-wrap"
                          >
                            Address
                          </th>
                          <th
                            class="px-3"
                            v-if="!isLabel"
                            style="max-width: 120px !important; white-space: pre-wrap"
                          >
                            Schedule info
                          </th>
                          <th
                            class="px-3"
                            v-if="!isLabel"
                            style="max-width: 150px; white-space: pre-wrap"
                          >
                            Status
                          </th>
                          <th class="px-3" v-if="isLabel">File</th>
                          <th class="px-3">Options</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template>
                          <tr
                            v-if="!isLabel"
                            v-for="(item, index) in printJobs"
                            :key="index"
                          >
                            <td class="px-2">
                              <v-checkbox
                                v-model="print_job_ids"
                                :value="item.id"
                              ></v-checkbox>
                              <!--                              <span-->
                              <!--                                class="ml-4 pl-2 badge badge-success"-->
                              <!--                                v-if="item.is_completed"-->
                              <!--                              >-->
                              <!--                                Marked as complete-->
                              <!--                              </span>-->
                            </td>

                            <td style="max-width: 150px; white-space: pre-wrap">
                              {{ item.candidate_name }}
                              <div class="mb-1" v-if="item.exam_key">
                                <a
                                  href="#"
                                  @click="getCandidateSummary(item.exam_key)"
                                  class="text-primary font-weight-bolder text-hover-primary mb-1"
                                  ><u>{{ item.exam_key }} </u>
                                </a>
                              </div>
                            </td>

                            <td style="max-width: 150px; white-space: pre-wrap">
                              <div
                                style="max-width: 100px !important; white-space: pre-wrap"
                              >
                                {{ item.enroler_name }}
                              </div>
                            </td>

                            <td>
                              <div
                                v-html="item.shipment_address"
                                style="max-width: 100px !important; white-space: pre-wrap"
                              ></div>
                            </td>
                            <td>
                              <div
                                style="max-width: 100px !important; white-space: pre-wrap"
                              >
                                {{ item.schedule_name ? item.schedule_name : "" }}
                              </div>
                              <div style="white-space: pre-wrap">
                                {{ item.exam_session_name ? item.exam_session_name : "" }}
                              </div>
                            </td>

                            <td style="max-width: 150px; white-space: pre-wrap">
                              <span
                                class="mb-5 badge badge-success"
                                v-if="item.is_completed"
                              >
                                Printed
                              </span>

                              <span
                                class="mb-5 badge badge-warning"
                                v-if="!item.is_completed"
                              >
                                Not printed
                              </span>
                              <br />

                              <div
                                style="max-width: 100px !important; white-space: pre-wrap"
                                class="mt-2 badge badge-success"
                                v-if="item.is_dispatched"
                              >
                                Sent
                              </div>

                              <div
                                style="max-width: 100px !important; white-space: pre-wrap"
                                class="mt-2 badge badge-danger"
                                v-else
                              >
                                Not sent
                              </div>
                            </td>

                            <td>
                              <v-btn
                                v-if="item.is_file_ready"
                                dark
                                rounded
                                x-large
                                @click="viewFile(item.file_path)"
                              >
                                Preview
                              </v-btn>
                              <div v-else>
                                NA
                              </div>
                            </td>
                          </tr>

                          <tr v-if="isLabel">
                            <td class="px-2">
                              <v-checkbox
                                v-if="!printJobs[0].is_completed"
                                v-model="print_job_ids"
                                :value="printJobs[0].id"
                              ></v-checkbox>
                              <span
                                class="ml-4 pl-2 badge badge-success"
                                v-if="printJobs[0].is_completed"
                              >
                                Mark as complete
                              </span>
                            </td>
                            <td>
                              <span class="font-size-lg">
                                {{ userNotification.title }}
                              </span>
                            </td>
                            <td>
                              <v-btn
                                color="success"
                                small
                                class="text-white"
                                @click="viewFile(printJobs[0].file_path)"
                              >
                                <v-icon small elevation="2" outlined>fas fa-eye</v-icon
                                >&nbsp; View
                              </v-btn>
                            </td>
                          </tr>
                          <tr v-if="printJobs.length == 0">
                            <td colspan="6" class="text-center">No items found</td>
                          </tr>
                        </template>
                      </tbody>
                    </table>

                    <b-pagination
                      v-if="printJobs.length > 0"
                      class="pull-right mt-7"
                      @input="getPrintJobs"
                      v-model="page"
                      :total-rows="total"
                      :disabled="loading"
                      :per-page="perPage"
                      first-number
                      last-number
                    ></b-pagination>
                  </div>
                </v-col>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import PrintJobService from "@/services/print-job/PrintJobService";
import PendingPrintService from "@/services/pending-print/PendingPrintService";
import CandidateService from "@/services/candidate/CandidateService";
import UserNotificationService from "@/services/user-notification/UserNotificationService";
import { required } from "vuelidate/lib/validators";

const printJob = new PrintJobService();
const pendingPrint = new PendingPrintService();
const candidate = new CandidateService();
const userNotification = new UserNotificationService();

export default {
  validations: {
    print_job_ids: { required },
  },
  data() {
    return {
      total: null,
      perPage: null,
      page: null,
      loading: false,
      isLabel: false,
      printJobs: [],
      print_job_ids: [],
      checkAllPrintJobFlag: false,
      isMarkAsCompleteLoading: false,
      isMarkAsDispatchedLoading: false,
      isPrintJobDeleting: false,
      userNotification: "",
      printLabelLoading: false,
      isDeleting: false,
    };
  },
  computed: {
    showCheckAllBox() {
      // let completedValueExist = this.printJobs.filter((item) => {
      //   return item.is_completed == 0;
      // });
      // if (completedValueExist.length == 0) return false;
      return true;
    },
  },
  methods: {
    exportBulkPrinter() {
      printJob.exportBulkPrinter(this.userNotificationId);
    },
    checkAllPrintJob() {
      if (this.checkAllPrintJobFlag) {
        this.printJobs.forEach((item) => {
          this.print_job_ids.push(item.id);
        });
      } else {
        this.print_job_ids = [];
      }
    },
    markNotificationAsRead() {
      userNotification
        .markAsViewed(this.userNotificationId)
        .then((response) => {})
        .catch((err) => {});
    },
    checkIfPrintJobTypeIsLabel() {
      if (this.printJobs && this.printJobs.length > 0) {
        if (this.printJobs[0].type == "label") {
          this.isLabel = true;
        }
      }
    },
    getPrintJobs() {
      this.loading = true;
      printJob
        .getByUserNotification({}, this.page, this.userNotificationId)
        .then((response) => {
          this.printJobs = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
          this.markNotificationAsRead();
          this.checkIfPrintJobTypeIsLabel();
        })
        .catch((err) => {
          this.loading = false;
        })
        .finally(() => {});
    },
    viewFile(filePath) {
      window.open(filePath, "_blank");
    },
    markAsComplete() {
      let data = "";
      if (this.isLabel) {
        data = {
          user_notification_id: this.printJobs[0].user_notification_id,
          type: "label",
        };
      } else {
        data = {
          print_job_ids: this.print_job_ids,
          user_notification_id: this.printJobs[0].user_notification_id,
          type: "label",
        };
      }

      if (this.$v.$error) {
        this.$snotify.error("Minimum of one file is required to continue");
      } else {
        this.isMarkAsCompleteLoading = true;
        printJob
          .markAsCompleted(data)
          .then((response) => {
            this.$snotify.success(response.data.message);
            this.isMarkAsCompleteLoading = false;
            this.print_job_ids = [];
            this.checkAllPrintJobFlag = false;
            this.isLabel = false;
            this.printJobs = [];
            this.getPrintJobs();
          })
          .catch((err) => {
            this.$snotify.error("Oops looks like something went wrong.");
            this.isMarkAsCompleteLoading = false;
          })
          .finally(() => {});
      }
    },
    markAsDispatched() {
      let data = {
        print_job_ids: this.print_job_ids,
      };
      if (this.$v.$error) {
        this.$snotify.error("Minimum of one file is required to continue'");
      } else {
        this.isMarkAsDispatchedLoading = true;
        candidate
          .markAsDispatched(data)
          .then((response) => {
            this.$snotify.success("Marked as dispatched");
            this.isMarkAsDispatchedLoading = false;
            this.print_job_ids = [];
            this.checkAllPrintJobFlag = false;
            this.getPrintJobs();
          })
          .catch((err) => {
            this.$snotify.error("Oops looks like something went wrong.");
            this.isMarkAsCompleteLoading = false;
          })
          .finally(() => {});
      }
    },
    showUserNotification() {
      userNotification.show(this.userNotificationId).then((res) => {
        this.userNotification = res.data.userNotification;
      });
    },
    printLabels() {
      let data = {
        print_job_ids: this.print_job_ids,
        category: this.printJobs[0].category,
      };
      this.printLabelLoading = true;
      pendingPrint
        .printLabels(data)
        .then((res) => {
          this.$snotify.success(res.data.message);
          this.printLabelLoading = false;
        })
        .catch((err) => {
          this.$snotify.error("Oops looks like something went wrong.");
          this.printLabelLoading = false;
        })
        .finally(() => {
          this.print_job_ids = [];
          this.checkAllPrintJobFlag = false;
        });
    },

    getCandidateSummary(examKey) {
      this.$router.push({
        name: "candidate-summary",
        params: { examKey: examKey },
      });
    },

    deleteNotification() {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isDeleting = true;

            userNotification
              .delete(this.userNotificationId)
              .then((res) => {
                this.$snotify.success("Notification deleted");
                this.$tabs.close();
              })
              .catch((err) => {
                this.$snotify.success("Oops looks like something went wrong.");
              })
              .finally(() => {
                this.isDeleting = false;
              });
          }
        },
      });
    },
    deletePrintJobs() {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isPrintJobDeleting = true;

            if (this.userNotification.title.includes("label")) {
              userNotification
                .delete(this.userNotificationId)
                .then((res) => {
                  this.$snotify.success("Notification deleted");
                  this.$tabs.close();
                })
                .catch((err) => {
                  this.$snotify.success("Oops looks like something went wrong.");
                })
                .finally(() => {
                  this.isDeleting = false;
                });
            } else {
              printJob
                .deleteMultiple(this.print_job_ids)
                .then((res) => {
                  this.$snotify.success("Prints deleted from notification");
                  this.showUserNotification();
                  this.getPrintJobs();
                  this.print_job_ids = [];
                })
                .catch((err) => {
                  this.$snotify.error("Oops looks like something went wrong.");
                })
                .finally(() => {
                  this.isPrintJobDeleting = false;
                });
            }
          }
        },
      });
    },
  },
  mounted() {
    this.userNotificationId = this.$route.params.userNotificationId;
    this.getPrintJobs();
    this.showUserNotification();
  },
};
</script>

<style scoped>
.list-style-none {
  list-style: none;
}
</style>
